import { createTheme, experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { fontStyleOverrides } from "./fontStyleOverrides";
import { themeFonts } from "./themeFonts.const";
import { themeColors } from "constants/colors.const";

export const baseTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1280,
            xl: 1440,
        },
    },
    palette: {
        text: {
            primary: themeColors.text.black,
            secondary: themeColors.text.white,
            tertiary: themeColors.text.tertiary,
            quaternary: themeColors.text.quaternary,
            disabled: themeColors.text.grey,
            black: themeColors.text.fullBlack,
        },
        background: {
            default: themeColors.brandColors.brandWhite,
            primary: themeColors.brandColors.brandBlack,
            secondary: themeColors.text.fullBlack,
            tertiary: themeColors.text.fullBlack,
            quaternary: themeColors.background.white,
        },
        brandColors: {
            brandGreen: themeColors.brandColors.brandGreen,
            brandBlack: themeColors.brandColors.brandBlack,
            brandWhite: themeColors.brandColors.brandWhite,
            brandLightgrey: themeColors.brandColors.brandLightgrey,
        },
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: `
                ${fontStyleOverrides}
            `,
        },
    },
});

export const theme = extendTheme(baseTheme, {
    typography: {
        h1: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "34px",
            lineHeight: 1.285,
            fontWeight: 700,
        },
        h3: {
            fontFamily: themeFonts.salara,
            fontSize: "28px",
            lineHeight: 1.285,
            fontWeight: 700,
        },
        title2Regular: {
            fontFamily: themeFonts.salara,
            fontSize: "22px",
            lineHeight: 1.333,
            fontWeight: 400,
        },
        titleBold: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "24px",
            lineHeight: 1.333,
            fontWeight: 800,
        },
        title1Bold: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "22px",
            lineHeight: 1.333,
            fontWeight: 700,
        },
        body1Bold: {
            fontFamily: themeFonts.geologica,
            fontSize: "20px",
            lineHeight: 1.2,
            fontWeight: 700,
        },
        body2Regular: {
            fontFamily: themeFonts.salara,
            fontSize: "16px",
            lineHeight: 1.5,
            fontWeight: 400,
        },
        body2Medium: {
            fontFamily: themeFonts.salara,
            fontSize: "16px",
            lineHeight: 1.5,
            fontWeight: 500,
        },
        body2Semibold: {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "16px",
            lineHeight: 1.5,
            fontWeight: 600,
        },
        body1Medium: {
            fontFamily: themeFonts.geologica,
            fontSize: "22px",
            lineHeight: 1.428,
            fontWeight: 500,
        },
        body1CaptionBold: {
            fontFamily: themeFonts.salara,
            fontSize: "16px",
            lineHeight: 1.333,
            fontWeight: 700,
        },
        smallRegular: {
            fontFamily: themeFonts.salara,
            fontSize: "14px",
            lineHeight: 1.333,
            fontWeight: 400,
        },
        smallThin: {
            fontFamily: themeFonts.geologica,
            fontSize: "11px",
            lineHeight: 1.333,
            fontWeight: 300,
        },
        smallBold: {
            fontFamily: themeFonts.salara,
            fontSize: "14px",
            lineHeight: 1.333,
            fontWeight: 700,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    "&:disabled": {
                        background: themeColors.state.buttonDisabledBg,
                        color: themeColors.text.white,
                    },
                    "&:hover": {
                        background: themeColors.state.buttonHoverBg,
                        color: themeColors.text.white,
                    },
                },
            },

            variants: [
                {
                    props: { variant: "themePrimary" },
                    style: {
                        backgroundColor: themeColors.brandColors.brandGreen,
                        fontFamily: themeFonts.sourceCodePro,
                        color: themeColors.text.white,
                        fontWeight: 800,
                        fontSize: "24px",
                        padding: "18px 130px",
                        borderRadius: "16px",
                        height: "67px",
                    },
                },
                {
                    props: { variant: "themePrimarySmall" },
                    style: {
                        backgroundColor: themeColors.brandColors.brandGreen,
                        color: themeColors.text.white,
                        fontWeight: 800,
                        fontSize: "16px",
                        padding: "12px 40px",
                        borderRadius: "12px",
                    },
                },
                {
                    props: { variant: "xs" },
                    style: {
                        backgroundColor: themeColors.brandColors.brandGreen,
                        color: themeColors.text.white,
                        fontWeight: 700,
                        fontSize: "12px",
                        padding: ".25rem .5rem",
                        borderRadius: "6px",
                        "&.disabled-active-view:disabled": {
                            color: "#fff !important",
                        },
                    },
                },
            ],
        },
    },
});
