import { forwardRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Stack, styled, Typography } from "@mui/material";
import { themeFonts } from "mui-theme/themeFonts.const";
import { ampli } from "services/ampli";
import { SidebarTypes } from "enums/SidebarTypes.enum";
import { Languages } from "enums/languages.enum";
import { AppRoutes } from "enums/routes.enum";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { getLanguageOptions, saveLanguage } from "helpers/language.helpers";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { themeColors } from "constants/colors.const";
import { NAV_MENU } from "./navMenu.model";
import { useLogout } from "hooks/useLogout";

interface Props {
    open: boolean;
    contenttype: SidebarTypes;
    onClose: () => void;
}

const StyledSidebar = styled("div", {
    shouldForwardProp: () => true,
})<Props>(({ theme, open }) => ({
    position: "fixed",
    minWidth: "400px",
    background: theme.palette.background.primary,
    transition: "0.4s",
    top: 0,
    right: 0,
    height: "100%",
    boxSizing: "border-box",
    zIndex: 9,
    transform: `translateX(${open ? "0" : "105%"})`,
    padding: "110px 16px 24px",

    "@media (max-width: 480px)": {
        minWidth: "100%",
        width: "100%",
    },
}));

const StyledLanguageButton = styled(Button)(() => ({
    height: "44px",
    width: "calc(50% - .5rem)",
    borderRadius: "10px",
    color: themeColors.text.white,
    background: themeColors.background.whiteOpacity4,
    border: "2px solid transparent",
    fontFamily: themeFonts.salara,
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "capitalize",

    "&.active": {
        borderColor: themeColors.brandColors.brandLightgrey,
        background: themeColors.background.primary,
        color: themeColors.brandColors.brandGreen,
    },
}));

export const Sidebar = forwardRef<HTMLDivElement, Props>(({ open, contenttype, onClose }, ref) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const { user } = useUserStore();
    const { flowOuter } = useAppData();
    const logout = useLogout();

    const isAccountPage = pathname === AppRoutes.ACCOUNT;
    const isSearchPage = pathname === AppRoutes.SEARCH;
    const showNavigation = !isAccountPage && !isSearchPage;

    useEffect(() => {
        if (document.body) {
            document.body.style.overflowY = open ? "hidden" : "unset";
        }
    }, [open]);

    const handleLanguagePick = (id: Languages) => {
        saveLanguage(id.toUpperCase());
        setTimeout(() => onClose(), 300);
    };

    const handleMenuItemClick = (title: string) => {
        handleAmpliLoaded(() => ampli.landingMenuClicked({ chosen_navigation_item: title }));
        onClose();
    };

    return (
        <StyledSidebar ref={ref} open={open} contenttype={contenttype} onClose={onClose}>
            {contenttype === SidebarTypes.Navigation && (
                <Stack direction="column" style={{ height: "100%" }}>
                    {showNavigation &&
                        NAV_MENU.map((item) => {
                            const showItem = item.showOnLegalFlow || (!item.showOnLegalFlow && flowOuter);
                            if (showItem) {
                                return (
                                    <Link key={item.id} to={item.href} style={{ marginBottom: "24px" }} onClick={() => handleMenuItemClick(item.title)}>
                                        <Typography variant="title1Bold" color={themeColors.text.white}>
                                            {t(item.title)}
                                        </Typography>
                                    </Link>
                                );
                            }
                        })}
                    <Button
                        variant="themePrimary"
                        sx={{ marginTop: "auto", whiteSpace: "nowrap", fontWeight: 800 }}
                        onClick={() => {
                            onClose();
                            if (user) {
                                logout();
                            } else {
                                navigate(AppRoutes.LOG_IN);
                            }
                        }}
                    >
                        {user ? t("Log out") : t("Log in")}
                    </Button>
                </Stack>
            )}
            {contenttype === SidebarTypes.LanguageMenu && (
                <Stack direction="row" flexWrap="wrap" gap="1rem">
                    {getLanguageOptions?.map((item) => (
                        <StyledLanguageButton
                            onClick={() => handleLanguagePick(item.id)}
                            key={item.id}
                            className={item.id.toLowerCase() === i18n.language.toLowerCase() ? "active" : undefined}
                        >
                            {item.title}
                        </StyledLanguageButton>
                    ))}
                </Stack>
            )}
        </StyledSidebar>
    );
});

Sidebar.displayName = "Sidebar";
