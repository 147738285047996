import { AppStore } from "./app.store";
import { LocationsStore } from "./locations.store";
import { UserStore } from "./user.store";

export type RootStoreHydration = {
    appStore: {
        searchPhoneNumber: string;
    };
};

export class RootStore {
    appStore: AppStore;
    userStore: UserStore;
    locationsStore: LocationsStore;

    constructor() {
        this.appStore = new AppStore(this);
        this.userStore = new UserStore(this);
        this.locationsStore = new LocationsStore(this);
    }

    hydrate(data: RootStoreHydration) {
        this.appStore.hydrate(data);
    }
}
