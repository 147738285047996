import { Typography, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { NAV_MENU } from "./navMenu.model";
import { useTranslation } from "react-i18next";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { useAppData } from "providers/RootStoreProvider";
import { useLanguageDetect } from "hooks/useLanguageDetect";

interface NavMenuProps {
    children: ReactNode;
}

const StyledNavMenu = styled("ul")<NavMenuProps>(() => ({
    display: "flex",
    alignItems: "stretch",
    background: themeColors.background.primary,
    borderRadius: "10px",
    border: "1px solid rgba(45, 49, 55, 0.05)",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    width: "fit-content",
    marginTop: "40px",
}));

const StyledNavMenuItem = styled("li")<NavMenuProps>(() => ({
    padding: "8px 24px",
}));

export const HeaderNavMenu = () => {
    const { t } = useTranslation();
    const { isVietnameseLanguage } = useLanguageDetect();
    const { flowOuter } = useAppData();
    const handleNavItemClick = (title: string) => {
        handleAmpliLoaded(() => ampli.landingMenuClicked({ chosen_navigation_item: title }));
    };

    return (
        <StyledNavMenu>
            {NAV_MENU.map((item) => {
                const showItem = item.showOnLegalFlow || (!item.showOnLegalFlow && flowOuter);
                if (showItem) {
                    return (
                        <StyledNavMenuItem key={item.id}>
                            <Link to={item.href} onClick={() => handleNavItemClick(item.title)}>
                                <Typography variant="body2Semibold" fontSize={isVietnameseLanguage ? "14px" : undefined}>
                                    {t(item.title)}
                                </Typography>
                            </Link>
                        </StyledNavMenuItem>
                    );
                }
            })}
        </StyledNavMenu>
    );
};
