import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let NewPassword = lazy(() => import("./NewPassword"));

export async function lazyNewPasswordLoader() {
    const componentModule = await import("./NewPassword");
    // This avoid flicker from React.lazy by using the component directly
    NewPassword = componentModule.default as any;

    return null;
}

export function LazyNewPassword() {
    return (
        <Suspense fallback={<PageFallback />}>
            <NewPassword />
        </Suspense>
    );
}
