import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let RestrictedAccessByIp = lazy(() => import("./RestrictedAccessByIp"));

export async function lazyRestrictedAccessByIpLoader() {
    const componentModule = await import("./RestrictedAccessByIp");
    // This avoid flicker from React.lazy by using the component directly
    RestrictedAccessByIp = componentModule.default as any;

    return null;
}

export function LazyRestrictedAccessByIp() {
    return (
        <Suspense fallback={<PageFallback />}>
            <RestrictedAccessByIp />
        </Suspense>
    );
}
