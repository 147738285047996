import DeclinePayment from "pages/DeclinePayment";
import SuccessPayment from "pages/SuccessPayment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const PaymentResult = () => {
    const [searchParams] = useSearchParams();
    const successParam = searchParams.get("success");
    const isSuccess = successParam === "true";

    useEffect(() => {
        window.top?.postMessage({ type: "3DS-complete", payload: { success: successParam } });
    }, []);

    return <>{isSuccess ? <SuccessPayment /> : <DeclinePayment />}</>;
};
