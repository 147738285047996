import { ReactNode, useEffect, useRef, useState } from "react";
import { WidthContainer } from "components/shared";
import { HeaderNavMenu } from "./NavMenu";
import { ampli } from "services/ampli";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { Sidebar } from "./Sidebar";
import { Box, Button, Stack, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { SidebarTypes } from "enums/SidebarTypes.enum";
import { LanguagePicker } from "./LanguagePicker";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "enums/routes.enum";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { isValidUrl } from "helpers/isValidUrl";
import { useLogout } from "hooks/useLogout";

interface Props {
    background?: string;
    customButton?: ReactNode;
    children?: ReactNode;
}

const StyledHeader = styled("header", {
    shouldForwardProp: () => true,
})<Props>(() => ({
    height: 0,
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    "& *": {
        flexShrink: 0,
    },
}));

export const Header = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isTablet } = useDeviceDetect();
    const { i18n, t } = useTranslation();
    const { user } = useUserStore();
    const { flowOuter } = useAppData();
    const [searchParams] = useSearchParams();
    const logout = useLogout();
    const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
    const [languagePickerOpen, setLanguagePickerOpen] = useState(false);
    const [sidebar, setSidebar] = useState({
        type: SidebarTypes.Navigation,
        isOpen: false,
    });
    const redirectSearchParam = searchParams.get("redirect");
    const isLoggedIn = !!user?.email;
    const languagePickerRef = useRef<HTMLDivElement | null>(null);
    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const sidebarTogglerRef = useRef<HTMLButtonElement | null>(null);

    const isHomePage = pathname === AppRoutes.HOME;
    const isAccountPage = pathname === AppRoutes.ACCOUNT;
    const isSearchPage = pathname === AppRoutes.SEARCH;
    const isLocationPage = pathname.includes(AppRoutes.LOCATION);
    const isLegalPage = pathname === AppRoutes.COOKIE_POLICY || pathname === AppRoutes.TERMS_OF_USE || pathname === AppRoutes.PRIVACY_POLICY;
    const isPaymentErrorPage = pathname === AppRoutes.DECLINE;
    const isPaymentPage = pathname === AppRoutes.PAYMENT;

    const isPAymentFlowOuter = isPaymentPage && !flowOuter;

    const isCloseButtonShown = (!isHomePage && !isSearchPage && !isAccountPage && !isPaymentPage) || isPAymentFlowOuter;
    const isMenuTogglerShown = isTablet && (isHomePage || isAccountPage || isSearchPage);
    const renderLoginButton = isHomePage || isAccountPage || isSearchPage;
    const isNavigateBack = isLegalPage || isLocationPage || isPaymentErrorPage;
    const disabledLogoRedirect = isAccountPage;

    const handleLogoClick = (e) => {
        if (redirectSearchParam && isValidUrl(redirectSearchParam)) {
            e.preventDefault();
            window.location.href = redirectSearchParam;
        } else if (disabledLogoRedirect) {
            e.preventDefault();
        }
    };

    const toggleSidebar = (type?: SidebarTypes) => {
        if (type) {
            setSidebar({ isOpen: !sidebar.isOpen, type });
        } else {
            setSidebar({ ...sidebar, isOpen: !sidebar.isOpen });
        }
    };

    const handleClickOutsideLanguagePicker = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (languagePickerRef.current && !languagePickerRef.current.contains(target) && !isTablet) {
            setLanguagePickerOpen(false);
        }
    };

    const handleClickOutsideSidebar = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (
            sidebarRef.current &&
            !sidebarRef.current.contains(target) &&
            !sidebarTogglerRef.current?.contains(target) &&
            !languagePickerRef.current?.contains(target)
        ) {
            setSidebar({ ...sidebar, isOpen: false });
        }
    };

    useEffect(() => {
        if (languagePickerOpen) {
            document.addEventListener("click", handleClickOutsideLanguagePicker);

            return () => {
                document.removeEventListener("click", handleClickOutsideLanguagePicker);
            };
        }
    }, [languagePickerOpen]);

    useEffect(() => {
        if (sidebar.isOpen) {
            document.addEventListener("click", handleClickOutsideSidebar);

            return () => {
                document.removeEventListener("click", handleClickOutsideSidebar);
            };
        }
    }, [sidebar.isOpen]);

    const handleLanguagePickerClick = () => {
        if (isTablet) {
            if (sidebar.isOpen && sidebar.type === SidebarTypes.LanguageMenu) {
                setSidebar({ ...sidebar, isOpen: false });
            } else if (sidebar.isOpen && sidebar.type !== SidebarTypes.LanguageMenu) {
                setSidebar({ ...sidebar, type: SidebarTypes.LanguageMenu });
            } else {
                setSidebar({
                    isOpen: true,
                    type: SidebarTypes.LanguageMenu,
                });
            }
        } else {
            setLanguagePickerOpen(!languagePickerOpen);
        }
    };

    const handleCloseButtonClick = () => {
        if (isValidUrl(redirectSearchParam)) {
            if (redirectSearchParam) window.location.href = redirectSearchParam;
        } else {
            if (pathname === AppRoutes.SENT_SMS) {
                handleAmpliLoaded(() => ampli.messageExit());
                navigate(AppRoutes.ACCOUNT);
            } else if (isPaymentPage) {
                navigate(AppRoutes.ACCOUNT);
            } else if (isNavigateBack) {
                navigate(-1);
            } else {
                navigate(AppRoutes.HOME);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 50) {
                setIsHeaderScrolled(true);
            } else {
                setIsHeaderScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <StyledHeader style={{ zIndex: isPaymentPage ? 5 : 10 }}>
                <WidthContainer>
                    <Link
                        to={isLoggedIn ? AppRoutes.ACCOUNT : AppRoutes.HOME}
                        onClick={handleLogoClick}
                        style={{
                            padding: "2px 10px",
                            borderRadius: "16px",
                            background:
                                isHeaderScrolled && !sidebar.isOpen && (isHomePage || isAccountPage || isLegalPage)
                                    ? themeColors.background.primary
                                    : "transparent",
                            boxSizing: "border-box",
                            height: "44px",
                            marginLeft: "-10px",
                            float: "left",
                            marginTop: isTablet ? "1rem" : "40px",
                        }}
                    >
                        <img
                            src={sidebar.isOpen || (!isHomePage && !isAccountPage && !isLegalPage) ? "/icons/logo-white-closer-dot.svg" : "/icons/Logo.svg"}
                            alt="Geomobile"
                        />
                    </Link>
                    {!isTablet && isHomePage && <HeaderNavMenu />}
                    <Stack
                        gap="16px"
                        direction="row"
                        justifyContent="flex-end"
                        ref={languagePickerRef}
                        sx={{ float: "right", marginTop: isTablet ? "1rem" : "40px" }}
                    >
                        <Box sx={{ position: "relative" }}>
                            {flowOuter && (
                                <Button
                                    style={{
                                        padding: 0,
                                        width: "44px",
                                        height: "44px",
                                        borderRadius: "10px",
                                        background: themeColors.background.primary,
                                        color: themeColors.brandColors.brandGreen,
                                        border: `${isTablet ? "2px" : "1px"} solid ${themeColors.brandColors.brandLightgrey}`,
                                        fontSize: "16px",
                                        minWidth: "44px",
                                        fontWeight: 700,
                                    }}
                                    onClick={handleLanguagePickerClick}
                                >
                                    {i18n.language}
                                </Button>
                            )}
                            {languagePickerOpen && <LanguagePicker onClose={() => setLanguagePickerOpen(false)} />}
                        </Box>
                        {!isTablet && renderLoginButton && (
                            <Button
                                variant="themePrimarySmall"
                                sx={{ height: "44px", textTransform: "uppercase" }}
                                onClick={() => {
                                    if (user) {
                                        logout();
                                    } else {
                                        navigate(AppRoutes.LOG_IN);
                                    }
                                }}
                            >
                                {user ? t("Log out") : t("Log in")}
                            </Button>
                        )}
                        {isMenuTogglerShown && (
                            <Button
                                ref={sidebarTogglerRef}
                                style={{
                                    padding: 0,
                                    width: "44px",
                                    height: "44px",
                                    borderRadius: "10px",
                                    background: themeColors.background.primary,
                                    color: themeColors.brandColors.brandGreen,
                                    border: `${isTablet ? "2px" : "1px"} solid ${themeColors.brandColors.brandLightgrey}`,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    minWidth: "44px",
                                }}
                                onClick={() => toggleSidebar(SidebarTypes.Navigation)}
                            >
                                <img src={sidebar.isOpen ? "/icons/close.svg" : "/icons/burger-toggler.svg"} alt="" />
                            </Button>
                        )}
                        {isCloseButtonShown && (
                            <Button
                                style={{
                                    padding: 0,
                                    width: "44px",
                                    height: "44px",
                                    borderRadius: "10px",
                                    background: themeColors.background.primary,
                                    color: themeColors.brandColors.brandGreen,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    minWidth: "44px",
                                    border: `2px solid ${themeColors.brandColors.brandLightgrey}`,
                                }}
                                onClick={handleCloseButtonClick}
                            >
                                <img src="/icons/close.svg" alt="" />
                            </Button>
                        )}
                    </Stack>
                </WidthContainer>
            </StyledHeader>
            <Sidebar ref={sidebarRef} open={sidebar.isOpen} contenttype={sidebar.type} onClose={toggleSidebar} />
        </>
    );
};
