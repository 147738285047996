import { FC, ImgHTMLAttributes } from "react";

interface imgUrlProps {
    media?: string;
    url: string;
}

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    webp?: imgUrlProps;
    png?: imgUrlProps;
    jpg?: imgUrlProps;
    svg?: imgUrlProps;
}

export const Image: FC<Props> = ({ webp, png, jpg, svg, ...imgProps }) => {
    return (
        <picture>
            {webp?.url && <source srcSet={webp.url} media={webp.media} type="image/webp" />}
            {png?.url && <source srcSet={png.url} media={png.media} type="image/webp" />}
            {jpg?.url && <source srcSet={jpg.url} media={jpg.media} type="image/webp" />}
            {svg?.url && <source srcSet={svg.url} media={svg.media} type="image/webp" />}
            <img src={svg?.url || jpg?.url || png?.url} {...imgProps} />
        </picture>
    );
};
