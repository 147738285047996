import MUIGlobalStyles from "@mui/material/GlobalStyles";

export const GlobalStyle = () => {
    return (
        <MUIGlobalStyles
            styles={{
                body: {
                    width: "100%",
                    maxWidth: "100%",
                    margin: 0,
                    padding: 0,
                    overflowX: "hidden",
                },
                a: {
                    textDecoration: "none",
                    color: "inherit",
                },
                ul: {
                    listStyle: "none",
                    margin: 0,
                    padding: 0,
                },
            }}
        />
    );
};
