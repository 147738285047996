import { styled } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

const StyledContainer = styled("div")<Props>(({ theme }) => ({
    width: "86%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
        width: "calc(100% - 40px)",
    },
}));

export const WidthContainer = ({ children, className, style }: Props) => (
    <StyledContainer style={style} className={className}>
        {children}
    </StyledContainer>
);
