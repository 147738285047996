import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let DeclinePayment = lazy(() => import("./DeclinePayment"));

export async function lazyDeclinePaymentLoader() {
    const componentModule = await import("./DeclinePayment");
    // This avoid flicker from React.lazy by using the component directly
    DeclinePayment = componentModule.default as any;

    return null;
}

export function LazyDeclinePayment() {
    return (
        <Suspense fallback={<PageFallback />}>
            <DeclinePayment />
        </Suspense>
    );
}
