import { PropsWithChildren, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { supabase } from "services/supabase";
import { useUserStore } from "providers/RootStoreProvider";
import { useBoolean } from "../../hooks/useBoolean";
import { useQuery } from "../../hooks/useQuery";
import { getSignInToken } from "../../http-client/auth.client";
import { saveTokensToStorage } from "../../services/storage";

export const AuthCheck = observer(({ children }: PropsWithChildren) => {
    const { setUser, fetchUserProfile } = useUserStore();
    const [isUserChecked, setChecked] = useBoolean();
    const query = useQuery();
    const hash = query.get("funnel");

    const retrieveFunnelSession = async (hash: string) => {
        if (!hash) {
            return;
        }

        const { data } = await supabase.auth.verifyOtp({
            token_hash: hash,
            type: "email",
        });

        const userId = data?.user?.id;
        const amplitudeId = data?.user?.user_metadata?.amplitudeId;

        if (userId && amplitudeId) {
            const tokens = await getSignInToken(userId, amplitudeId);
            saveTokensToStorage(tokens);
        }
    };

    useEffect(() => {
        if (hash) {
            retrieveFunnelSession(hash);
        }
    }, [hash]);

    useEffect(() => {
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange(async (_event, session) => {
            const user = session?.user ?? null;

            setUser(user);
            if (user) {
                await fetchUserProfile(user?.email);
            }
            setChecked.on();
        });

        return () => subscription.unsubscribe();
    }, []);

    if (!isUserChecked) {
        return null;
    }

    return <>{children}</>;
});
