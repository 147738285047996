import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let SuccessPayment = lazy(() => import("./SuccessPayment"));

export async function lazySuccessPaymentLoader() {
    const componentModule = await import("./SuccessPayment");
    // This avoid flicker from React.lazy by using the component directly
    SuccessPayment = componentModule.default as any;

    return null;
}

export function LazySuccessPayment() {
    return (
        <Suspense fallback={<PageFallback />}>
            <SuccessPayment />
        </Suspense>
    );
}
