import { AppRoutes } from "enums/routes.enum";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { useUserStore } from "providers/RootStoreProvider";
import { ampli } from "services/ampli";
import { removeTokensFromStorage } from "services/storage";
import { supabase } from "services/supabase";

export const useLogout = () => {
    const { setUser } = useUserStore();

    const logout = () => {
        supabase.auth
            .signOut()
            .then(() => {
                handleAmpliLoaded(() => ampli.accountLogoutClicked());

                setUser(null);
                removeTokensFromStorage();

                window.location.assign(AppRoutes.LOG_IN);
            })
            .catch((error) => {
                console.error("error: ", error);
            });
    };

    return logout;
};
