export const themeColors = {
    background: {
        primary: "#EFEFEF",
        secondary: "#2D3137",
        teal: "#58B287",
        white: "#fff",
        whiteOpacity7: "rgba(255,255,255,0.7)",
        whiteOpacity4: "rgba(255, 255, 255, .4)",
        borderLightGrey: "#C2C2C2",
        lightGrey: "rgba(45, 49, 55, 0.05)",
    },
    text: {
        black: "#2D3137",
        green: "#58B287",
        white: "#fff",
        grey: "#B2B2B2",
        lightgrey: "#CBCBCB",
        lighterGrey: "#8B8B8B",
        tertiary: "rgba(45, 49, 55, 0.8)",
        quaternary: "#72789A",
        fullBlack: "#000",
        lightGreen: "rgba(45, 49, 55, 0.5)",
        placeholderText: "rgba(45, 49, 55, 0.65)",
    },
    brandColors: {
        brandGreen: "#58B287",
        brandBlack: "#2D3137",
        brandWhite: "#EFEFEF",
        brandLightgrey: "#CBCBCB",
    },
    state: {
        error: "#BF5252",
        buttonDisabledBg: "rgba(88, 178, 135, 0.3)",
        buttonHoverBg: "#67BF95",
    },
};
