import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let Share = lazy(() => import("./ShareNonLegal"));

export async function lazyShareNonLegalLoader() {
    const componentModule = await import("./ShareNonLegal");
    // This avoid flicker from React.lazy by using the component directly
    Share = componentModule.default as any;

    return null;
}

export function LazyShareNonLegal() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Share />
        </Suspense>
    );
}
