import { createClient, User } from "@supabase/supabase-js";

import { SUPABASE_KEY, SUPABASE_URL } from "constants/env.const";

interface Fbuser {
    uid: string;
    email: string;
    disabled: boolean;
    metadata: Metadata;
    passwordHash: string;
    passwordSalt: string;
    providerData: Provider[];
    emailVerified: boolean;
    tokensValidAfterTime: string;
}

interface Metadata {
    creationTime: string;
    lastSignInTime: string;
}

interface Provider {
    uid: string;
    email: string;
    providerId: string;
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

export const getUserId = (user: User) => {
    const fbUser = user.user_metadata?.fbuser as Fbuser;

    return fbUser?.uid ?? user.id;
};
