import { Stack, Typography, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { Languages } from "enums/languages.enum";
import { getLanguageOptions, saveLanguage } from "helpers/language.helpers";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    onClose: () => void;
}

const StyledLanguagePicker = styled(Stack)(() => ({
    position: "absolute",
    left: "50%",
    bottom: "0",
    transform: "translate(-50%, 105%)",
    width: "fit-content",
    maxWidth: "200px",
    padding: "16px",
    borderRadius: "10px",
    background: themeColors.background.primary,
}));

const StyledLanguageOption = styled(Typography)(() => ({
    textTransform: "uppercase",
    cursor: "pointer",
    color: themeColors.text.grey,
    textAlign: "center",
    "&.active": {
        color: themeColors.text.green,
    },
}));

export const LanguagePicker: FC<Props> = ({ onClose }) => {
    const { i18n } = useTranslation();
    const handleLanguagePick = (id: Languages) => {
        saveLanguage(id.toUpperCase());
        onClose();
    };

    return (
        <StyledLanguagePicker gap=".5rem">
            {getLanguageOptions?.map((lang) => (
                <StyledLanguageOption
                    key={lang.id}
                    className={lang.id.toLowerCase() === i18n.language.toLowerCase() ? "active" : undefined}
                    onClick={() => handleLanguagePick(lang.id)}
                    variant="body1CaptionBold"
                >
                    {lang.title}
                </StyledLanguageOption>
            ))}
        </StyledLanguagePicker>
    );
};
