import Home, { homeLoader } from "pages/Home";
import { AppRoutes } from "enums/routes.enum";
import FakeSearch, { fakeSearchLoader } from "pages/FakeSearch";
import SignUp, { signUpLoader } from "pages/SignUp";
import Payment, { PaymentLoader } from "pages/Payment";
import LogIn, { logInLoader } from "pages/LogIn";
import Account, { accountLoader } from "pages/Account";
import SentSms, { sentSmsLoader } from "pages/SentSms";
import Location, { locationLoader } from "pages/Location";
import ForgotPassword, { forgotPasswordLoader } from "pages/ForgotPassword";
import NewPassword, { newPasswordLoader } from "pages/NewPassword";
import SuccessPayment, { successPaymentLoader } from "pages/SuccessPayment";
import DeclinePayment, { declinePaymentLoader } from "pages/DeclinePayment";
import { PaymentResult } from "pages/PaymentResult";
import CookiePolicy, { cookiePolicyLoader } from "pages/CookiePolicy";
import TermsOfUse, { termsOfUseLoader } from "pages/TermsOfUse";
import PrivacyPolicy, { privacyPolicyLoader } from "pages/PrivacyPolicy";
import RestrictedAccessByIp, { restrictedAccessByIpLoader } from "pages/RestrictedAccessByIp";
import { LazyShareLegal, lazyShareLegalLoader, LazyShareNonLegal, lazyShareNonLegalLoader } from "pages/Share";

export const routes = [
    {
        path: AppRoutes.HOME,
        component: Home,
        loader: homeLoader,
    },
    {
        path: AppRoutes.SEARCH,
        component: FakeSearch,
        loader: fakeSearchLoader,
    },
    {
        path: AppRoutes.SIGN_UP,
        component: SignUp,
        loader: signUpLoader,
    },
    {
        path: AppRoutes.PAYMENT,
        component: Payment,
        loader: PaymentLoader,
    },
    {
        path: AppRoutes.LOG_IN,
        component: LogIn,
        loader: logInLoader,
    },
    {
        path: AppRoutes.ACCOUNT,
        component: Account,
        loader: accountLoader,
    },
    {
        path: AppRoutes.SENT_SMS,
        component: SentSms,
        loader: sentSmsLoader,
    },
    {
        path: `${AppRoutes.LOCATION}/:locationId`,
        component: Location,
        loader: locationLoader,
    },
    {
        path: AppRoutes.FORGOT_PASSWORD,
        component: ForgotPassword,
        loader: forgotPasswordLoader,
    },
    {
        path: AppRoutes.NEW_PASSWORD,
        component: NewPassword,
        loader: newPasswordLoader,
    },
    {
        path: AppRoutes.SUCCESS,
        component: SuccessPayment,
        loader: successPaymentLoader,
    },
    {
        path: AppRoutes.DECLINE,
        component: DeclinePayment,
        loader: declinePaymentLoader,
    },
    {
        path: AppRoutes.SECURE_FLOW_RESULT,
        component: PaymentResult,
        loader: successPaymentLoader,
    },
    {
        path: AppRoutes.COOKIE_POLICY,
        component: CookiePolicy,
        loader: cookiePolicyLoader,
    },
    {
        path: AppRoutes.TERMS_OF_USE,
        component: TermsOfUse,
        loader: termsOfUseLoader,
    },
    {
        path: AppRoutes.PRIVACY_POLICY,
        component: PrivacyPolicy,
        loader: privacyPolicyLoader,
    },
    {
        path: `${AppRoutes.SHARE_NON_LEGAL}/:locationId`,
        component: LazyShareNonLegal,
        loader: lazyShareNonLegalLoader,
    },
    {
        path: `${AppRoutes.SHARE_LEGAL}/:locationId`,
        component: LazyShareLegal,
        loader: lazyShareLegalLoader,
    },
    {
        path: `${AppRoutes.RESTRICTED_ACCESS}`,
        component: RestrictedAccessByIp,
        loader: restrictedAccessByIpLoader,
    },
];
