import { Box, styled } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { FC, ReactNode } from "react";

interface Props {
    sx?: CSSProperties;
    children: ReactNode;
}

const StyledFormContainer = styled(Box)<Props>(({ theme }) => ({
    zIndex: 5,
    width: "350px",
    padding: "1.5rem",
    background: theme.palette.background.default,
    borderRadius: "20px",
    border: `2px solid ${theme.palette.brandColors.brandGreen}`,
    margin: "10.25rem auto 0",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "100px auto 0",
    },
}));

export const FormContainer: FC<Props> = ({ sx, children }) => <StyledFormContainer sx={sx}>{children}</StyledFormContainer>;
