/* Get/Set alternative payout flag in backend sessions */
export enum SessionAltPayout {
    FM = "FM" /* food-mentor */,
}

/* Get paymentPage property in plan object (from '/subscription-plans' endpoint) */
export enum PaymentPageType {
    Geomobile = "geomobile",
    LocationTracker = "location-tracker",
    FoodMentor = "food-mentor",
}

/* Choose type of plans shown on payout (PAYMENT_PLAN_TYPE variable)*/
export enum PaymentPlanType {
    TRIAL = "TRIAL",
    SUBSCRIPTION_WITH_TRIAL = "SUBSCRIPTION WITH TRIAL",
    SUBSCRIPTION = "SUBSCRIPTION",
}

export enum PaymentProvider {
    IXOPAY = "ixopay",
    PAYPAL = "paypal",
}
