import { Backdrop, CircularProgress } from "@mui/material";
import { themeColors } from "constants/colors.const";

export const PageFallback = () => {
    return (
        <Backdrop sx={{ color: themeColors.brandColors.brandGreen, zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
