import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let LogIn = lazy(() => import("./LogIn"));

export async function lazyLogInLoader() {
    const componentModule = await import("./LogIn");
    // This avoid flicker from React.lazy by using the component directly
    LogIn = componentModule.default as any;

    return null;
}

export function LazyLogIn() {
    return (
        <Suspense fallback={<PageFallback />}>
            <LogIn />
        </Suspense>
    );
}
