import { Stack, SxProps } from "@mui/material";
import { Image } from "./Image";
import { SectionGradientDarkener } from "./SectionGradientDarkener";
import { WidthContainer } from "./WidthContainer";
import { FC, ReactNode } from "react";
import { themeColors } from "constants/colors.const";
import { useDeviceDetect } from "hooks/useDeviceDetect";

interface Props {
    children: ReactNode;
    sx?: SxProps;
}

export const BackgroundImageSectionWrap: FC<Props> = ({ children, sx }) => {
    const { isTablet } = useDeviceDetect();

    return (
        <Stack
            sx={{
                width: "100%",
                position: "relative",
                background: themeColors.background.secondary,
                minHeight: "100vh",
                overflow: "hidden",
                paddingBottom: "5vh",
                ...sx,
            }}
        >
            <Image
                png={{ url: isTablet ? "/images/mobile-bg-highlights.png" : "/images/search-page-bg.png" }}
                webp={{ url: isTablet ? "/images/mobile-bg-highlights.webp" : "/images/search-page-bg.webp" }}
                style={{
                    position: "absolute",
                    width: "110%",
                    objectFit: "cover",
                    objectPosition: "center",
                }}
            />
            <SectionGradientDarkener />
            <WidthContainer style={{ position: "relative" }}>{children}</WidthContainer>
        </Stack>
    );
};
