import { useTranslation } from "react-i18next";

export const useLanguageDetect = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language.toLowerCase();

    const isEnglishLanguage = currentLanguage === "en";
    const isSpanishLanguage = currentLanguage === "es";
    const isFrenchLanguage = currentLanguage === "fr";
    const isPortugueseLanguage = currentLanguage === "pt";
    const isChineseLanguage = currentLanguage === "cn";
    const isArabicLanguage = currentLanguage === "sa";
    const isGermanLanguage = currentLanguage === "de";
    const isVietnameseLanguage = currentLanguage === "vn";
    const isTurkishLanguage = currentLanguage === "tr";

    return {
        isEnglishLanguage,
        isSpanishLanguage,
        isFrenchLanguage,
        isPortugueseLanguage,
        isChineseLanguage,
        isArabicLanguage,
        isGermanLanguage,
        isVietnameseLanguage,
        isTurkishLanguage,
    };
};
