export const fontStyleOverrides = `
    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Thin'),
            url('/fonts/static/geologica/woff2/Geologica_Auto-Thin.woff2') format('woff2');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Extra Light'),
            url('/fonts/static/geologica/woff2/Geologica_Auto-ExtraLight.woff2') format('woff2');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Regular'),
            url('/fonts/static/geologica/woff2/Geologica_Auto-Regular.woff2') format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Medium'),
            url('/fonts/static/geologica/woff2/Geologica_Auto-Medium.woff2') format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Semi Bold'),
            url('/fonts/static/geologica/woff2/Geologica_Cursive-SemiBold.woff2') format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Extra Bold'),
            url('/fonts/static/geologica/woff2/Geologica_Auto-ExtraBold.woff2') format('woff2');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Geologica';
        src:
            local('Geologica Black'),
            url('/fonts/static/geologica/woff2/Geologica_Auto-Black.woff2') format('woff2');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Source code pro';
        src:
            local('Source code black'),
            url('/fonts/static/source-code/SourceCodePro-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Source code pro';
        src:
            local('Source code extra bold'),
            url('/fonts/static/source-code/SourceCodePro-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Source code pro';
        src:
            local('Source code bold'),
            url('/fonts/static/source-code/SourceCodePro-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Source code pro';
        src:
            local('Source code semibold'),
            url('/fonts/static/source-code/SourceCodePro-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Source code pro';
        src:
            local('Source code medium'),
            url('/fonts/static/source-code/SourceCodePro-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Sarala';
        src:
            local('Sarala normal'),
            url('/fonts/static/sarala/Sarala-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Sarala';
        src:
            local('Sarala bold'),
            url('/fonts/static/sarala/Sarala-Bold.ttf') format('truetype');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }
`;
