import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let Payment = lazy(() => import("./Payment"));

export async function lazyPaymentLoader() {
    const componentModule = await import("./Payment");
    // This avoid flicker from React.lazy by using the component directly
    Payment = componentModule.default as any;

    return null;
}

export function LazyPayment() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Payment />
        </Suspense>
    );
}
