export const isValidUrl = (string: string | null = null) => {
    if (!string) return false;

    try {
        new URL(string);
        return true;
    } catch {
        return false;
    }
};
