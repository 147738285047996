import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToHashElement = () => {
    const location = useLocation();
    const [element, setElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        const hash = location.hash;
        setElement(document.getElementById(hash.slice(1)));
    }, [location]);

    useEffect(() => {
        const mq = window.matchMedia("(min-width: 600px)");
        const headerHeight: number = mq.matches ? 90 : 60;

        if (element) {
            window.scrollTo({
                top: element.offsetTop - headerHeight,
                behavior: "smooth",
            });
        }
    }, [element]);

    return null;
};
