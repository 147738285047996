import { styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { CSSProperties, FC } from "react";

interface Props {
    style?: CSSProperties;
}

const StyledGradientBlock = styled("div")(() => ({
    position: "absolute",
    width: "100%",
    height: "10rem",
    background: `linear-gradient(to bottom, transparent, ${themeColors.background.secondary})`,
    bottom: 0,
    left: 0,
}));

export const SectionGradientDarkener: FC<Props> = ({ style }) => <StyledGradientBlock style={style} />;
