import { Box, Typography, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { Link } from "react-router-dom";

export const StyledContainer = styled("section")(() => ({
    background: themeColors.background.primary,
    paddingTop: "140px",
}));

export const Title = styled(Typography)(() => ({
    marginBottom: "1rem",
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: 1,
}));

export const Anchor = styled(Link)(() => ({
    color: themeColors.text.green,
}));

export const Text = styled(Typography)(() => ({
    marginBottom: ".75rem",
    fontSize: "16px",
    fontWeight: 500,
}));

export const Block = styled(Box)(() => ({
    marginBottom: "3rem",
}));

export const List = styled("ul")(() => ({
    paddingLeft: "2rem",
    listStyle: "disc",
    marginBottom: "2rem",
}));

export const FakeTableCell = styled("div")(() => ({
    padding: "1rem 0",
    borderBottom: "1px solid rgba(0, 0, 0, .15)",
    "&:last-of-type": {
        paddingBottom: 0,
        borderBottom: "none",
    },
    "&:first-of-type": {
        paddingTop: 0,
    },
}));
