import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let TermsOfUse = lazy(() => import("./TermsOfUse"));

export async function lazyTermsOfUseLoader() {
    const componentModule = await import("./TermsOfUse");
    TermsOfUse = componentModule.default as any;

    return null;
}

export function LazyTermsOfUse() {
    return (
        <Suspense fallback={<PageFallback />}>
            <TermsOfUse />
        </Suspense>
    );
}
