import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

let Home = lazy(() => import("./Home"));

export async function lazyHomeLoader() {
    const componentModule = await import("./Home");
    // This avoid flicker from React.lazy by using the component directly
    Home = componentModule.default as any;

    return null;
}

export function LazyHome() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t("loading")}>
            <Home />
        </Suspense>
    );
}
