export const NAV_MENU = [
    {
        id: "1",
        title: "Features",
        href: "/#features",
        showOnLegalFlow: true,
    },
    {
        id: "2",
        title: "Feedbacks",
        href: "/#feedbacks",
        showOnLegalFlow: false,
    },
    {
        id: "3",
        title: "How it works",
        href: "/#how-it-works",
        showOnLegalFlow: true,
    },
    {
        id: "4",
        title: "Contacts",
        href: "#contacts",
        showOnLegalFlow: true,
    },
];
