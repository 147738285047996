import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let Location = lazy(() => import("./Location"));

export async function lazyLocationLoader() {
    const componentModule = await import("./Location");
    // This avoid flicker from React.lazy by using the component directly
    Location = componentModule.default as any;

    return null;
}

export function LazyLocation() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Location />
        </Suspense>
    );
}
