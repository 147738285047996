import { EndpointEnum } from "enums/endpoint.enum";
import { get, post } from "./http.client";
import { isResponseOk } from "helpers/isResponseOk";
import { GOOGLE_MAP_API_KEY, PROJECT_NAME } from "constants/env.const";
import axios from "axios";
import { ILocation } from "../interfaces/location/location.interface";

export const getLocationLink = async (name: string) => {
    try {
        const response = await post(`${EndpointEnum.LOCATIONS}/link`, { name });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error("error:", e);
    }
};

export type UpdateLocationProps = Required<Pick<ILocation, "latitude" | "longitude" | "target" | "details">>;

export const updateLocation = async (id: string, data: UpdateLocationProps) => {
    return post(`${EndpointEnum.LOCATIONS}/${id}`, data);
};

export const getLocation = async (locationId: string) => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}/${locationId}`, {});
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error("error:", e);
    }
};

export const getLocations = async () => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}`, {});
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error("error:", e);
    }
};

export interface SendLocationSmsProps {
    locationId: string;
    language: string;
    flowOuter: boolean;
}

export const sendLocationSms = async ({ locationId, language, flowOuter }: SendLocationSmsProps) => {
    try {
        const response = await post(`${EndpointEnum.LOCATIONS}/${locationId}/sms`, {
            project: PROJECT_NAME,
            language,
            flowOuter,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (err: any) {
        console.error("error:", err);
        throw new Error(err.message);
    }
};

export const getCoordinatesByCityName = async (cityName: string) => {
    try {
        const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
            params: {
                address: cityName,
                key: GOOGLE_MAP_API_KEY,
            },
        });
        const { results } = response.data;
        if (results && results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            return { lat, lng };
        } else {
            throw new Error("No results found");
        }
    } catch (error) {
        console.error("Error fetching coordinates:", error);
        throw error;
    }
};
