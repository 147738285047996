import { EndpointEnum } from "enums/endpoint.enum";
import { get } from "./http.client";
import { isResponseOk } from "helpers/isResponseOk";

export interface CarrierData {
    phone: string;
    country: string;
    city?: string;
    carrier: string;
}

export const getPhoneCarrier = async (phoneNumber: string) => {
    try {
        const response = await get<CarrierData>(`${EndpointEnum.PHONE}/carrier`, {
            number: phoneNumber,
        });

        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (err: any) {
        console.error("error:", err);
        throw new Error(err.message);
    }
};
