import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let CookiePolicy = lazy(() => import("./CookiePolicy"));

export async function lazyCookiePolicyLoader() {
    const componentModule = await import("./CookiePolicy");
    // This avoid flicker from React.lazy by using the component directly
    CookiePolicy = componentModule.default as any;

    return null;
}

export function LazyCookiePolicy() {
    return (
        <Suspense fallback={<PageFallback />}>
            <CookiePolicy />
        </Suspense>
    );
}
